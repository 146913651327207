// extracted by mini-css-extract-plugin
export var cardBg = "TechStacksTheraForge-module--cardBg--cd009";
export var cards = "TechStacksTheraForge-module--cards--9d683";
export var description = "TechStacksTheraForge-module--description--c6629";
export var heading = "TechStacksTheraForge-module--heading--33150";
export var infaHeading = "TechStacksTheraForge-module--infaHeading--a8e37";
export var nav = "TechStacksTheraForge-module--nav--5466f";
export var navItem = "TechStacksTheraForge-module--nav-item--412ce";
export var navbarBlock = "TechStacksTheraForge-module--navbarBlock--196c5";
export var tabData = "TechStacksTheraForge-module--tabData--e646a";
export var tech = "TechStacksTheraForge-module--tech--cb2f5";
export var techIconssCard = "TechStacksTheraForge-module--techIconssCard--ff406";
export var techInfrastructures = "TechStacksTheraForge-module--techInfrastructures--27fe4";
export var techStackImg = "TechStacksTheraForge-module--techStackImg--f434e";
export var techStacksCardsContent = "TechStacksTheraForge-module--techStacksCardsContent--48219";
export var techStacksIcon = "TechStacksTheraForge-module--techStacksIcon--3b293";
export var techStacksTheraForgeData = "TechStacksTheraForge-module--techStacksTheraForgeData--a394d";
export var technologyStacksIcon = "TechStacksTheraForge-module--technologyStacksIcon--5dd10";