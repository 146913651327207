import React from "react"
import * as styles from "./ObjectiveTheraforgeMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const ObjectiveTheraforgeMobile = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.objectiveMobileTheraforge}>
        <Container className={styles.theraforgeContainerMobile}>
          <Row>
            <Col>
              <h2
                className={styles.objectiveTheraforgeHeadingMobile}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={styles.objectiveDescriptionMobileTheraforge}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ObjectiveTheraforgeMobile
