import React from "react"
import * as styles from "./ProblemsSolutions.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const ProblemsSolutions = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.problemsSolutionsTheraforge}>
        <Container>
          <Row>
            <Col lg={6} md={12} xs={12} sm={12} className={styles.problemsCol}>
              <h2 className={styles.problemsHeading}>Problems Client Faced</h2>
              <p className={styles.problemsDescription}>
                Currently, advanced digital health software development is quite
                hard as it is heavily burdened by several infrastructural costs,
                which can add up to 60-80% of the overall development and
                maintenance costs.
                <br /> <br /> These often non-IP-related costs greatly detract
                from the time and resources that companies could allocate to
                their innovation/IP. Usability and uptake can also be hampered,
                as frequently documented by lackluster user adoption.
              </p>
            </Col>
            <Col lg={6} md={12} xs={12} sm={12} className={styles.solutionsCol}>
              <h2
                className={styles.solutionHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <div className={styles.cards}>
                {strapiData?.cards &&
                  strapiData?.cards?.map((item, index) => (
                    <div className={styles.talent} key={index}>
                      <img
                        className={styles.tick}
                        src={
                          "https://invozone-backend.s3.us-east-1.amazonaws.com/hire_Cto_Check_4d62eb15a8.svg"
                        }
                        alt={"hire cto check mark"}
                      />
                      <div className={styles.text}>
                        <p>{item?.subTitle}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProblemsSolutions
