// extracted by mini-css-extract-plugin
export var cards = "ProblemsSolutions-module--cards--97da0";
export var problemsCol = "ProblemsSolutions-module--problemsCol--94564";
export var problemsDescription = "ProblemsSolutions-module--problemsDescription--3c464";
export var problemsHeading = "ProblemsSolutions-module--problemsHeading--11a46";
export var problemsSolutionsTheraforge = "ProblemsSolutions-module--problemsSolutionsTheraforge--5d785";
export var solutionHeading = "ProblemsSolutions-module--solutionHeading--73473";
export var solutionsCol = "ProblemsSolutions-module--solutionsCol--081c2";
export var talent = "ProblemsSolutions-module--talent--fa4e5";
export var text = "ProblemsSolutions-module--text--1d44e";
export var tick = "ProblemsSolutions-module--tick--939ac";