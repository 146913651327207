import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./TechStacksTheraForge.module.scss"
import Row from "react-bootstrap/Row"

const data = [
  {
    name: "NodeJS",
    icon: "https://invozone-backend.s3.amazonaws.com/nodejs_f1ad2a2c65.svg",
  },
  {
    name: "ExpressJS",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/express_js_1d0c5c5e66.svg",
  },
  {
    name: "ReactJS",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_js_bcdb2524c0.svg",
  },
  {
    name: "CouchDB",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/couchdb_d3f755ed15.svg",
  },
  {
    name: "Postgres",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgres_b9aaa14d73.svg",
  },
  {
    name: "Event Source",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/event_source_ff4837a9d0.svg",
  },
  {
    name: "SSE",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sse_5b440ba83f.svg",
  },
  {
    name: "Swift",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/swift_76c8fa0756.svg",
  },
]

const TechStacksTheraForge = ({ strapiData }) => {
  return (
    <div className="TechStacksTabs">
      <div className={styles.techStacksTheraForgeData}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div className={styles.techStacksCardsContent}>
            <Row className={styles.techStacksIcon}>
              {data &&
                data?.map((e, i) => (
                  <div className={` ${styles.techIconssCard}`} key={i}>
                    <div className={styles.technologyStacksIcon}>
                      <div className={styles.techStackImg}>
                        <img
                          decoding="async"
                          loading="lazy"
                          src={e?.icon}
                          alt={e?.name}
                        />
                      </div>
                      <h3>{e?.name}</h3>
                    </div>
                  </div>
                ))}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  )
}
export default TechStacksTheraForge
